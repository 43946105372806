.BlogPost_image {
  margin-bottom: 1rem;
  width: 100%;

  img {
    width: 100%;
  }
}

.BlogPost_body {
  max-width: 1000px;
  font-size: 1.25rem;
}

.BlogPostTease {
  h6 {
    margin-bottom: 0.5rem !important;
  }
}