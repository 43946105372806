@import '../../utils/mixins.postcss';
@import '../../variables.postcss';

$breakpoint: $large;

.SidebarLayout {
  @mixin above $breakpoint {
    min-height: calc(100vh - $nav-height);
  }
}

.SidebarLayout :global(.Container) {
  @mixin above $breakpoint {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: top;
  }
}

.SidebarLayout_sidebar {
  display: none;
  @mixin above $breakpoint {
    display: block;
    padding-top: 2rem;
    flex: 0 0 360px;
    min-height: calc(100vh - $nav-height);
  }
}

.SidebarLayout_body {
  margin-bottom: 2rem;

  @mixin above $breakpoint {
    margin-bottom: 0;
    flex: 1;
    overflow: hidden;
    padding: 2rem 2.5rem 2rem 0;
  }
}

.Sidebar__marginTop {
  @mixin above $breakpoint {
    margin-top: 1.75rem;
  }
}

.SidebarBookingCta {
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  margin-bottom: 2rem;
  padding: 1rem;

  img {
    display: block;
    margin: 1rem auto;
  }

  :global(.Button) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.Sidebar {
  position: sticky;
  top: 10rem;
}
