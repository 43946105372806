@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.Triple_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.Triple {
  flex: 1 0 100%;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  & > a {
    color: inherit;

    &:hover {
      color: $otherGrey;
    }
  }

  @mixin above $medium {
    flex: 0 1 calc(33.33% - 0.67rem);
    margin-bottom: 0;

    &:not(:nth-child(3n)) {
      margin-right: 1rem;
    }
  }


  div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-bottom: 66.66%;
    width: 100%;
  }

  img {
    display: block;
    margin: 0;
    width: 100%;
  }

  h6 {
    margin: 1rem 0 0.8rem;
  }

  p {
    margin: 0;
  }
}

.Triple_wrapper__two .Triple {
  @mixin above $medium {
    flex: 0 1 calc(50% - 0.5rem);

    &:nth-child(odd) {
      margin-right: 1rem;
    }

    &:nth-child(even) {
      margin-right: 0;
    }
  }
}

.Triple_wrapper__services .Triple {
  @mixin above $medium {
    div {
      padding-bottom: 39.39%;
    }
  }
}